<template>
  <a-modal
    :visible="visible"
    :wrap-class-name="$style.modalSync"
    :closable="false"
    :width="700"
  >
    <template v-if="showSuccessIntegrate">
      <img :src="require(`@/assets/store/add-store/success-add.svg`)" style="width:60px !important; height:60px !important;" />
      <h4 class="mt-4">
        Otorisasi Gudang Berhasil!
      </h4>
      <div class="mt-4 w-100 text-center">
        Berhasil menambahkan gudang utama dan gudang channel.
      </div>
    </template>
    <template v-else>
      <h5 class="text-center w-100 mt-4">
        Warehouse
      </h5>
      <template v-if="selectedData?.sales_channel?.code === 'blibli_id'">
        <a-form-model ref="formBlibli" :model="formBlibli" class="form-blibli">
          <a-row :gutter="12" class="warehouse-blibli-header">
            <a-col :span="12">
              List Gudang Channel
            </a-col>
            <a-col :span="12">
              List Gudang Powerbiz
            </a-col>
          </a-row>
          <a-row
            v-for="(warehouse, index) in formBlibli.warehouses"
            :key="warehouse.pickup_point_id"
            :gutter="16"
            type="flex"
            justify="space-around"
            align="middle"
            class="warehouse-blibli-list"
          >
            <a-col :span="12" style="line-height: 1.5">
              {{ warehouse.name }} - {{ warehouse.display_name }}
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                :prop="'warehouses.' + index + '.warehouse_id'"
                :rules="{
                  required: true,
                  message: 'Gudang Powerbiz harus dipilih',
                  trigger: 'change',
                }"
                class="mb-0"
              >
                <a-select
                  v-model="warehouse.warehouse_id"
                  size="large"
                  class="w-100 select-antd-default"
                  show-search
                  allow-clear
                  :options="listWarehouseMaster"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  :placeholder="$t('add_store.select_main_warehouse')"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </template>
      <template v-else>
        <a-form-model
          ref="ruleForm"
          class="w-100"
          :model="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-card
            v-for="(formWarehouse, index) in form.warehouseMaster"
            :key="formWarehouse+index"
            class="w-100 mt-2"
          >
            <div
              v-if="index > 0"
              class="position-absolute top-0 right-0 mt-1 mr-2 close-icon"
              @click="() => onRemoveSelectedWarehouse(index)"
            >
              <CloseIcon
                :color="'black'"
              />
            </div>
            <a-form-model-item
              :label="'Warehouse Utama'"
              :rules="[{
                required: true,
                trigger: 'change',
              }]"
            >
              <a-select
                v-model="form.warehouseMaster[index]"
                :show-arrow="true"
                show-search
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="(opt, index) in listWarehouseMaster"
                  :key="opt.value+index"
                  :value="opt.value"
                  :title="opt.label"
                >
                  {{ opt.label }}
                </a-select-option>
                <template #suffixIcon>
                  >
                </template>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :label="'Warehouse Channel'"
              :rules="{
                required: true,
                trigger: 'change',
              }"
              class="mb-0"
            >
              <a-select
                v-model="form.warehouseChannel[index]"
                :show-arrow="true"
                show-search
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="(opt, index) in listWarehouseChannel"
                  :key="opt.value+index"
                  :value="opt.value"
                  :title="opt.label"
                >
                  {{ opt.label }}
                </a-select-option>
                <template #suffixIcon>
                  >
                </template>
                <template slot="notFoundContent">
                  <a-empty :image="simpleImage" />
                  <div
                    class="d-flex justify-content-center btn"
                    style="padding: 4px 8px; cursor: pointer"
                    @mousedown="e => e.preventDefault()"
                    @click="getWarehouseChannelList()"
                  >
                    refresh
                  </div>
                </template>
              </a-select>
            </a-form-model-item>
          </a-card>
        </a-form-model>
        <a-button
          v-if="((form?.warehouseMaster[form?.warehouseMaster?.length - 1] && form?.warehouseChannel[form?.warehouseChannel?.length - 1]) && (form?.warehouseChannel?.length < listWarehouseChannel?.length && form?.warehouseMaster?.length < listWarehouseMaster?.length))"
          type="primary"
          class="d-flex align-items-center w-100 justify-content-center mt-3"
          @click.prevent="onAddWarehouse"
        >
          <PlusIcon class="mr-2" :color="'white'" />
          Tambah Gudang Lain
        </a-button>
      </template>
    </template>
    <template #footer>
      <template v-if="showSuccessIntegrate">
        <a-button
          type="primary"
          @click="$emit('emitSuccessAddWarehouse', null)"
        >
          OK
        </a-button>
      </template>
      <template v-else>
        <a-button
          class="mr-2"
          @click="openModal(null), resetForm()"
        >
          Kembali
        </a-button>
        <template v-if="selectedData?.sales_channel?.code === 'blibli_id'">
          <a-button
            type="primary"
            form="form-integrate"
            :disabled="!(form.warehouseMaster[0] && formBlibli.warehouses)"
            :loading="loading"
            @click.prevent="loading = true, handleMapWarehouse()"
          >
            Submit
          </a-button>
        </template>
        <template v-else>
          <a-button
            type="primary"
            form="form-integrate"
            :disabled="!selectedData?.sales_channel?.code === 'shopee_id' && !(form.warehouseMaster[0] && form.warehouseChannel[0])"
            :loading="loading"
            @click.prevent="loading = true, handleSubmitForm()"
          >
            Submit
          </a-button>
        </template>
      </template>
    </template>
  </a-modal>
</template>

<script>
import PlusIcon from '@/components/Icons/PlusIcon.vue'
import CloseIcon from '@/components/Icons/Close.vue'
import { Empty } from 'ant-design-vue';
import { getBlibliDataDetail, getBlibliPickupPoint, mappingBlibliPickupPoint } from '@/api/channels/blibli'

export default {
  name: 'ModalApproveConfirmation',
  components: {
    PlusIcon,
    CloseIcon,
  },
  props: {
    visible: Boolean,
    openModal: Function,
    openModalSuccess: Function,
    selectedData: Object,
  },
  data: function () {
    return {
      store_id: null,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      showSuccessIntegrate: false,
      form: {
        warehouseMaster: [''],
        warehouseChannel: [''],
      },
      listWarehouseMaster: [],
      listWarehouseChannel: [],
      loading: false,
      formBlibli: {
        warehouses: [],
      },
    }
  },
  computed: {
    integration() {
      return this.$store.state.integration
    },
  },
  watch: {
    selectedData: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (
          value && value.id
        ) {
          if(value?.sales_channel?.code.includes('blibli')) this.getBlibliStoreDetail()
          this.getWarehouseChannelList()
          this.getWarehouseList()
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    async handleSubmitForm() {
      this.loading = true
      if (['shopee_id', 'tiktok', 'tokopedia_id'].includes(this.selectedData?.sales_channel?.code)) {
        const promises = []
        const delayIncrement = 1000
        let delay = 0
        this.form.warehouseMaster.map((obj, index) => {
          let isHaveWarehouse = Boolean(this.form.warehouseChannel[index])
          if (['shopee_id'].includes(this.selectedData?.sales_channel?.code) && this.form.warehouseChannel[index] === "") {
            isHaveWarehouse = true
          }
          if(obj && isHaveWarehouse) {
            promises.push(
              // eslint-disable-next-line no-async-promise-executor
              new Promise(async function(resolve) {
                await new Promise(res => setTimeout(res, delay))
                resolve()
              })
              .then(async () => {
                return await this.$store.dispatch('integration/UPDATEWAREHOUSECHANNEL', {
                  channel_code: this.selectedData?.sales_channel?.code === 'blibli_id'
                    ? 'blibli'
                    : this.selectedData?.sales_channel?.code,
                  business_id: this.$route.query.business_id,
                  payload: this.selectedData?.sales_channel?.code === 'blibli_id'
                    ? {
                        store_id: this.store_id,
                        channel_id: this.selectedData?.id,
                        pickup_point_id: this.form.warehouseChannel[index],
                        warehouse_id: obj,
                      }
                    : {
                        channel: {
                          id: this.selectedData?.id,
                          code: this.selectedData?.sales_channel?.code,
                          name: this.selectedData?.title,
                        },
                        warehouse_id: obj,
                        warehouse_channel: {
                          id: this.selectedData?.sales_channel?.code === 'shopee_id' && this.form.warehouseChannel[index] === 'default'
                            ? ''
                            : this.form.warehouseChannel[index],
                          name: this.listWarehouseChannel.find((obj2) => obj2.value ===  this.form.warehouseChannel[index])?.label ?? '',
                        },
                        user_id: this.$store.state.user.id,
                      },
                })
              }),
            )
            delay += delayIncrement
          }
        })
        this.promisesAllIntegrateWarehouse(promises)
      } else if (['lazada_id'].includes(this.selectedData?.sales_channel?.code)) {
        const warehouses = this.form.warehouseMaster.map((obj, index) => {
          if(obj && this.form.warehouseChannel[index] !== null) {
            return {
              warehouse_id: obj,
              channel_warehouse_id: this.form.warehouseChannel[index],
              channel_warehouse_name: this.listWarehouseChannel.find((obj2) => obj2.value ===  this.form.warehouseChannel[index])?.label ?? '',
            }
          }
        })
        await this.$store.dispatch('integration/UPDATEWAREHOUSELAZADA', {
          channel_id: this.selectedData?.id,
          payload: { warehouses },
        })
          .then(() => {
            this.loading = false
            this.showSuccessIntegrate = true
          })
          .catch(err => {
            this.loading = false
            console.error(err)
            this.$notification.error({
              message: err.response?.data?.message,
            })
            this.correctionConnectedWarehouse()
          })
      } else {
        this.loading = false
      }
    },
    promisesAllIntegrateWarehouse(promises) {
      if (promises) {
        Promise.all(promises)
          .then(() => {
            this.loading = false
            this.showSuccessIntegrate = true
          })
          .catch(err => {
            this.loading = false
            console.error(err)
            this.$notification.error({
              message: err.response?.data?.message,
            })
            this.correctionConnectedWarehouse()
          })
      }
    },
    correctionConnectedWarehouse() {
      this.$store.dispatch('warehouse/GETCONNECTEDWAREHOUSECHANNELLIST', {
        channel_id: this.selectedData?.id,
        sales_channel: this.selectedData?.sales_channel?.code === 'blibli_id'
          ? 'blibli'
          : this.selectedData?.sales_channel?.code,
        business_id: this.$route.query.business_id,
      })
      .then(data => {
        let newFormWarehouseMaster = []
        let newFormWarehouseChannel = []
        this.form?.warehouseMaster?.map((whsMasterId, index) => {
          let currentWhsMasterId = whsMasterId
          let currentWhsChannelId = this.form?.warehouseChannel[index]
          let findSameWhs = data.findIndex((obj) => obj.warehouse_channel_id === currentWhsChannelId && obj.warehouse_master_id === currentWhsMasterId)
          if(findSameWhs === -1) {
            newFormWarehouseMaster.push(currentWhsMasterId)
            newFormWarehouseChannel.push(currentWhsChannelId)
          }
        })
        this.form.warehouseMaster = newFormWarehouseMaster
        this.form.warehouseChannel = newFormWarehouseChannel
      })
      .catch(err => {
        console.error(err)
        return err
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    resetForm() {
      this.listWarehouseMaster = []
      this.listWarehouseChannel = []
      this.form = {
        warehouseMaster: [''],
        warehouseChannel: [''],
      }
      this.formBlibli.warehouses = []
    },
    getWarehouseList() {
      this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
          channel_code: this.selectedData?.sales_channel?.code,
        })
        .then(response => {
          this.listWarehouseMaster = response
          if (response?.length > 0) {
            this.form.warehouseMaster[0] = response[0].value
          }
        })
        .catch(err => {
          console.error(err)
          return err
        })
    },
    getWarehouseChannelList() {
      if (this.selectedData?.sales_channel?.code === 'shopee_id') {
        this.$store
          .dispatch('warehouse/GETSHOPEEWAREHOUSECHANNELLIST', {
            channel_id: this.selectedData?.id,
          })
          .then(response => {
            this.listWarehouseChannel = response
            if (response?.length > 0) {
              this.form.warehouseChannel[0] = response[0].value
            }
          })
          .catch(err => {
            console.error(err)
            return err
          })
      } else if (this.selectedData?.sales_channel?.code === 'lazada_id') {
        this.$store
          .dispatch('warehouse/GETLAZADAWAREHOUSECHANNELLIST', {
            channel_id: this.selectedData?.id,
          })
          .then(response => {
            this.listWarehouseChannel = response
            if (response?.length > 0) {
              this.form.warehouseChannel[0] = response[0].value
            }
          })
          .catch(err => {
            console.error(err)
            return err
          })
      } else if (this.selectedData?.sales_channel?.code === 'blibli_id') {
        this.$store
          .dispatch('warehouse/GETBLIBLIWAREHOUSECHANNELLIST', {
            business_id: this.$route.query.business_id,
            channel_id: this.selectedData?.id,
          })
          .then(response => {
            this.listWarehouseChannel = response
            if (response?.length > 0) {
              this.form.warehouseChannel[0] = response[0].value
            }
          })
          .catch(err => {
            console.error(err)
            return err
          })
      } else {
        this.$store
          .dispatch('warehouse/GETWAREHOUSECHANNELLIST', {
            channel_id: this.selectedData?.id,
            sales_channel: this.selectedData?.sales_channel?.code,
          })
          .then(response => {
            this.listWarehouseChannel = response
            if (response?.length > 0) {
              this.form.warehouseChannel[0] = response[0].value
            }
          })
          .catch(err => {
            console.error(err)
            return err
          })
      }
    },
    async getBlibliStoreDetail() {
      await getBlibliDataDetail({
        business_id: this.$route.query.business_id,
        channel_id: this.selectedData?.id,
      })
      .then(({ data: { data: response } }) => this.fetchBlibliPickupPoint(response?.store_id, response?.channel_id))
      .catch(err => console.error(err))
    },
    async fetchBlibliPickupPoint(store_id, channel_id) {
      await getBlibliPickupPoint({
        business_id: this.$route.query.business_id,
        channel_id,
      })
      .then(({ data }) => {
        this.formBlibli.warehouses = data?.data?.map(warehouse => ({
          store_id,
          channel_id,
          pickup_point_id: warehouse.id,
          name: warehouse.name,
          display_name: warehouse.display_name,
          warehouse_id: warehouse.master_warehouse_id || undefined,
        }))
      })
      .catch(err => console.error(err))
    },
    async handleMapWarehouse() {
      this.$refs.formBlibli.validate(async valid => {
        if (valid) {
          let promises = []

          this.formBlibli.warehouses.forEach(warehouse => {
            const { store_id, channel_id, pickup_point_id, warehouse_id } = warehouse
            promises.push(mappingBlibliPickupPoint({
              business_id: this.$route.query.business_id,
              data: {
                store_id,
                channel_id,
                pickup_point_id,
                warehouse_id,
              },
            }))
          })
          
          await Promise.all(promises)
            .then(response => {
              let successNum = 0
              response.forEach((res, index) => {
                if(res?.data?.status?.toLowerCase() === 'success') {
                  successNum++
                } else {
                  this.$notification.error({
                    message: `Integrasi Gudang ${this.formBlibli.warehouses[index].name} - ${this.formBlibli.warehouses[index].display_name} Gagal`,
                  })
                }
              })
              
              if(successNum) {
                this.showSuccessIntegrate = true
              }
            })
            .catch(err => this.$notification.error({ message: err.response?.data?.message }))
            .finally(() => this.loading = false)
        }
      })
    },
    onAddWarehouse() {
      this.form.warehouseMaster.push('')
      this.form.warehouseChannel.push('')
    },
    onRemoveSelectedWarehouse(idx) {
      this.form.warehouseMaster.splice(idx, 1)
      this.form.warehouseChannel.splice(idx, 1)
      document.activeElement.blur()
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss">
.form-blibli i {
  font-size: 12px !important;
  color: #c8c4db !important;
}

.warehouse-blibli {
  &-header {
    padding: 12px 0;
    font-weight: 600;
    background: #e9ecef;
  }

  &-list {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e4e9f0;
  }
}
</style>
